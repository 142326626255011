import React from 'react';

import ConfirmAccount from '../../components/auth/confirmAccount/confirmAccount';
import AuthLayout from '../../components/global/auth/authLayout/authLayout';
import { AuthPageBase } from '../../context/global-context';
import { AuthPageData, AuthPageType } from '../../types/auth';
import { LocalizationData } from '../../types/general';
import { PageContext } from '../../types/page';
import { LocaleEnum, getLocales, useI18n } from '../../utils/i18n';

type AuthCreateContext = PageContext & {
  locale: LocaleEnum;
  slug: string;
};

const ConfirmAccountPage: React.FC<{
  token: string;
  pageContext: AuthCreateContext;
}> = ({ token, pageContext }) => {
  const { translations, locale, slug } = pageContext;

  const i18n = useI18n(translations);
  const locales = getLocales([locale]);
  const localizationsData: LocalizationData[] = [];

  locales.forEach((l) => {
    let slug = i18n.t('account.confirmation.slug', l);
    slug = slug ? slug : 'account-confirmation';

    localizationsData.push({
      attributes: {
        slug: `${slug}/${token}`,
        locale: l,
      },
    });
  });

  const instance: AuthPageData = {
    authPageType: AuthPageType.confirmAccount,
    attributes: {
      slug: `${slug}/${token}`,
      locale: locale,
      localizations: {
        data: localizationsData,
      },
    },
  };

  return (
    <AuthPageBase translations={translations} instance={instance}>
      <AuthLayout>
        <ConfirmAccount token={token} />
      </AuthLayout>
    </AuthPageBase>
  );
};

export default ConfirmAccountPage;

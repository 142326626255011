/* eslint-disable @typescript-eslint/no-empty-function */
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';

import './confirmAccount.scss';
import GlobalContext from '../../../context/global-context';
import Loading from '../../global/loading/loading';

const ConfirmAccount: React.FC<{
  token: string;
}> = ({ token }) => {
  const { i18n } = useContext(GlobalContext);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      axios
        .get(
          `${process.env.GATSBY_STRAPI_URL}/api/auth/email-confirmation` +
            `?confirmation=${token}`
        )
        .then((response) => {
          if (response.status === 200) {
            setSuccess(true);
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
    }
  }, []);

  return (
    <div className="auth-content-wrapper">
      <div className="auth-form-wrapper confirm-account">
        <h2 className="auth-title">{i18n?.t('account.confirmation')}</h2>
        {loading && <Loading size="large" />}
        {!loading && !success && (
          <p>{i18n?.t('account.confirmation.invalid')}</p>
        )}
        {!loading && success && (
          <p>{i18n?.t('account.confirmation.success')}</p>
        )}
      </div>
    </div>
  );
};

export default ConfirmAccount;
